import * as React from 'react';
import { Route, Routes } from 'react-router';
import { Layout, Home, Counter, FetchData, Database, ManageDatabase } from './components';

import './custom.css'

export default () => (
    <Layout>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/counter' element={<Counter />} />
            <Route path='/database' element={<Database />} />
            <Route path='/manage' element={<ManageDatabase />} />
            <Route path='/fetch-data/:startDateIndex?' element={<FetchData/>} />
        </Routes>
    </Layout>
);
