import React from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

export const withRouter = (Component: any) => {
    const Wrapper = (props: any) => {
        const navigate = useNavigate();
        const [searchParams] = useSearchParams();
        const urlParams = useParams()
        return (
            <Component
                navigate={navigate}
                searchParams={searchParams}
                urlParams={urlParams}
                {...props}
            />
        );
    };

    return Wrapper;
};