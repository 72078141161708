export const uploadFile = {

    upload: (url: string, file: File, onProgress: (progress: number) => void) => {

        let data = new FormData();
        data.append('file', file);

        let request = new XMLHttpRequest();
        request.open('POST', url);

        // upload progress event
        request.upload.addEventListener('progress', function (e) {
            // upload progress as percentage
            let percent_completed = (e.loaded / e.total) * 100;

            onProgress(percent_completed);
        });

        // request finished event
        request.addEventListener('load', function (e) {
            // HTTP status message (200, 404 etc)
            console.log(request.status);

            // request.response holds response from the server
            console.log(request.response);
        });

        // send POST request to server
        request.send(data);

    }
};