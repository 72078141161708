import * as React from 'react';
import { useSearchParams } from "react-router-dom";
import { useFormik } from 'formik';

import { TextFilter, TextFilterForm } from '../types';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import './Database.css';

//const Item = styled(Paper)(({ theme }) => ({
//    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//    ...theme.typography.body2,
//    padding: theme.spacing(1),
//    textAlign: 'center',
//    color: theme.palette.text.secondary,
//}));

export const Database: React.FC = (props) => {

    const myRef = React.useRef<HTMLInputElement>(null);

    const [backdrop, setBackdrop] = React.useState(false);
    const [currentClause, setCurrentClause] = React.useState<{
        id: number;
        textId: number;
        brokenness: number;

        paragraph: number;
        paragraphPostfix: '',
        lineOriginal: '',
        linePrefix: '',
        lineMultiplier: number;
        startLine: number;
        endLine: number;
        syllables: '',
        normalizedSpelling: '',

        formattedSyllables: '',
        formattedNormalizedSpelling: '',

        englishTranslation: '',
        wordOrder: '',
        isQuestion: boolean;
        isNegation: boolean;

        markUp1: boolean;
        markUp2: boolean;
        markUp3: boolean;
        markUp4: boolean;
        markUp5: boolean;

        tregexAnnotation: '',

        order: number;
        starred: boolean;

        wordForms: Array<{
            id: number;
            clauseId: number;
            syllables: '',
            normalizedSpelling: '',
            formattedSyllables: '',
            formattedNormalizedSpelling: '',
            translation: '',
            lemma: '',
            poS: '',
            syntRole: '',
            wordOrder: number;
            dependency: number;
            order: number;
        }>;

        informationStructures: Array<{
            id: number;
            clauseId: number;
            qud: '',
            text: '',
        }>;
    }>();
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(1);
    const [textCount, setTextCount] = React.useState(0);
    const [clauseCount, setClauseCount] = React.useState(0);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);

    const [texts, setTexts] = React.useState<Array<{
        id: number;
        source: '',
        time: '',
        cth: number;
        title: '',
        publication: '',
        dublicate: '',
        order: number;
        clauses: Array<{
            id: number;
            textId: number;
            brokenness: number;

            paragraph: number;
            paragraphPostfix: '',
            lineOriginal: '',
            linePrefix: '',
            lineMultiplier: number;
            startLine: number;
            endLine: number;
            syllables: '',
            normalizedSpelling: '',

            formattedSyllables: '',
            formattedNormalizedSpelling: '',

            englishTranslation: '',
            wordOrder: '',
            isQuestion: boolean;
            isNegation: boolean;

            markUp1: boolean;
            markUp2: boolean;
            markUp3: boolean;
            markUp4: boolean;
            markUp5: boolean;

            tregexAnnotation: '',

            order: number;
            starred: boolean;

            wordForms: Array<{
                id: number;
                clauseId: number;
                syllables: '',
                normalizedSpelling: '',
                formattedSyllables: '',
                formattedNormalizedSpelling: '',
                translation: '',
                lemma: '',
                poS: '',
                syntRole: '',
                wordOrder: number;
                dependency: number;
                order: number;
            }>;

            informationStructures: Array<{
                id: number;
                clauseId: number;
                qud: '',
                text: '',
            }>;
        }>;
    }>>([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const formik = useFormik<TextFilterForm>({
        initialValues: {
            exect: false,

            publication: '',
            dublicate: '',
            time: '',
            cth: '',
            source: '',
            title: '',

            brokenness: '',
            paragraph: '',
            lines: '',
            syllables: '',
            normalizedSpelling: '',
            englishTranslation: '',
            isNegation: '',
            isQuestion: '',
            wordOrder: '',
            phraseStructure: '',

            wordFormSyllables: '',
            wordFormNormalizedSpelling: '',
            wordFormTranslation: '',
            lemma: '',
            poS: '',

            clausesPerPage: '10',
        },
        onSubmit: async (values, { setSubmitting }) => {

            await getSearch({
                ...values,
                isNegation: values.isNegation ? values.isNegation === 'true' : undefined,
                cth: values.cth ? parseInt(values.cth) : undefined,
                clausesPerPage: values.clausesPerPage ? parseInt(values.clausesPerPage) : undefined,
                brokenness: values.brokenness ? parseInt(values.brokenness) : undefined,
                isQuestion: values.isQuestion ? values.isQuestion === 'true' : undefined,
                paragraph: values.paragraph ? parseInt(values.paragraph) : undefined,
                page,
            });
        },
    });

    React.useEffect(() => {
        let searchClauseId = searchParams.get("clause_id");
        let searchTextId = searchParams.get("text_id");
        if (searchClauseId || searchTextId) {
            getSearch({
                clauseId: searchClauseId ? parseInt(searchClauseId) : undefined,
                textId: searchTextId ? parseInt(searchTextId) : undefined,
                clausesPerPage: formik.values.clausesPerPage ? parseInt(formik.values.clausesPerPage) : undefined,
                page: 1,
                exect: true,
            });
            if (searchClauseId) searchParams.delete('clause_id');
            if (searchTextId) searchParams.delete('text_id');
            setSearchParams();
        }
    }, []); // <-- empty dependency array

    const getSearch = React.useCallback(async (values: TextFilter) => {
        setBackdrop(true);

        let result = await fetch(`databaseapi/search`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(values) // body data type must match "Content-Type" header
        });

        if (!result.ok) {
            setBackdrop(false);
            return;
        }

        const resultJson = await result.json();
        //console.log(resultJson);

        setTexts(resultJson.texts);
        formik.setFieldValue('page', resultJson.page);
        setPage(resultJson.page);
        setTextCount(resultJson.textCount);
        setClauseCount(resultJson.clauseCount);
        const pageCount = Math.floor((resultJson.realClauseCount + 1) / resultJson.take);
        setCount(pageCount);
        myRef.current?.scrollIntoView();
        setBackdrop(false);
    }, []);

    const handleChangePage = async (event: React.ChangeEvent<unknown>, value: number) => {
        await getSearch({
            ...formik.values,
            isNegation: formik.values.isNegation ? formik.values.isNegation === 'true' : undefined,
            cth: formik.values.cth ? parseInt(formik.values.cth) : undefined,
            clausesPerPage: formik.values.clausesPerPage ? parseInt(formik.values.clausesPerPage) : undefined,
            brokenness: formik.values.brokenness ? parseInt(formik.values.brokenness) : undefined,
            isQuestion: formik.values.isQuestion ? formik.values.isQuestion === 'true' : undefined,
            paragraph: formik.values.paragraph ? parseInt(formik.values.paragraph) : undefined,
            page: value
        });
    };

    const handleExport = React.useCallback(async (exportNumber: string, fileName: string) => {
        let result = await fetch(`databaseapi/export${exportNumber}`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(
                {
                    ...formik.values,
                    isNegation: formik.values.isNegation ? formik.values.isNegation === 'true' : undefined,
                    cth: formik.values.cth ? parseInt(formik.values.cth) : undefined,
                    clausesPerPage: formik.values.clausesPerPage ? parseInt(formik.values.clausesPerPage) : undefined,
                    brokenness: formik.values.brokenness ? parseInt(formik.values.brokenness) : undefined,
                    isQuestion: formik.values.isQuestion ? formik.values.isQuestion === 'true' : undefined,
                    paragraph: formik.values.paragraph ? parseInt(formik.values.paragraph) : undefined,
                    page: undefined,
                }
            ) // body data type must match "Content-Type" header
        });

        if (!result.ok) {
            return;
        }

        console.log(result);

        const blob = await result.blob();

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

    }, [formik.values]);

    return <>
        <Container maxWidth="lg">
            <Paper sx={{ marginTop: '10px' }}>
                <div>
                    {searchParams.get("clauseId") }
                </div>
                <div>
                    {searchParams.get("textId")}
                </div>
                <Grid container>
                    <Grid sx={{ padding: '10px 0px 0px 10px ' }} item sm={12}>
                        <Typography variant="h5">Text</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <FormControl
                            variant="standard"
                            fullWidth
                        >
                            <InputLabel>Source</InputLabel>
                            <Select
                                label="Source"
                                variant="standard"
                                name="source"
                                value={formik.values.source}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                <MenuItem value={'letters'}>letters</MenuItem>
                                <MenuItem value={'instructions'}>instructions</MenuItem>
                                <MenuItem value={'prayers'}>prayers</MenuItem>
                                <MenuItem value={'myths'}>myths</MenuItem>
                                <MenuItem value={'other'}>other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Time"
                            name="time"
                            value={formik.values.time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <FormControl
                            variant="standard"
                            sx={{ minWidth: 120 }}
                            fullWidth
                        >
                            <InputLabel>Brokenness</InputLabel>
                            <Select
                                label="Brokenness"
                                variant="standard"
                                name="brokenness"
                                value={formik.values.brokenness}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                <MenuItem value={'1'}>1</MenuItem>
                                <MenuItem value={'2'}>2</MenuItem>
                                <MenuItem value={'3'}>3</MenuItem>
                                <MenuItem value={'4'}>4</MenuItem>
                                <MenuItem value={'5'}>5</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="CTH"
                            name="cth"
                            value={formik.values.cth}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Publication"
                            name="publication"
                            value={formik.values.publication}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid sx={{ padding: '10px 0px 0px 10px ' }} item sm={12}>
                        <Typography variant="h5">Clauses</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Paragraph"
                            name="paragraph"
                            value={formik.values.paragraph}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Lines"
                            name="lines"
                            value={formik.values.lines}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Syllables"
                            name="syllables"
                            value={formik.values.syllables}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Normalized spelling"
                            name="normalizedSpelling"
                            value={formik.values.normalizedSpelling}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Translation"
                            name="englishTranslation"
                            value={formik.values.englishTranslation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Word order"
                            name="wordOrder"
                            value={formik.values.wordOrder}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <FormControl
                            variant="standard"
                            sx={{ minWidth: 120 }}
                            fullWidth
                        >
                            <InputLabel>Interrogative</InputLabel>
                            <Select
                                label="Interrogative"
                                variant="standard"
                                name="isQuestion"
                                value={formik.values.isQuestion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                <MenuItem value={'true'}>yes</MenuItem>
                                <MenuItem value={'false'}>no</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <FormControl
                            variant="standard"
                            sx={{ minWidth: 120 }}
                            fullWidth
                        >
                            <InputLabel>Negative</InputLabel>
                            <Select
                                label="Interrogative"
                                variant="standard"
                                name="isNegation"
                                value={formik.values.isNegation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                <MenuItem value={'true'}>yes</MenuItem>
                                <MenuItem value={'false'}>no</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Phrase structure"
                            name="phraseStructure"
                            value={formik.values.phraseStructure}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid sx={{ padding: '10px 0px 0px 10px ' }} item sm={12}>
                        <Typography variant="h5">Word form</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Lemma"
                            name="lemma"
                            value={formik.values.lemma}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Normalized spelling"
                            name="wordFormNormalizedSpelling"
                            value={formik.values.wordFormNormalizedSpelling}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="Glosses"
                            name="wordFormTranslation"
                            value={formik.values.wordFormTranslation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <TextField
                            variant="standard"
                            label="PoS"
                            name="poS"
                            value={formik.values.poS}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ marginTop: '10px' }}>
                <Grid container>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <FormControlLabel control={
                            <Checkbox
                                name="exect"
                                checked={formik.values.exect}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} label="Match phrase" />
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={6} sm={3}>
                        <FormControl
                            variant="standard"
                            sx={{ minWidth: 120 }}
                            fullWidth
                        >
                            <InputLabel>Clauses per page</InputLabel>
                            <Select
                                label="Clauses per page"
                                variant="standard"
                                name="clausesPerPage"
                                value={formik.values.clausesPerPage}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value={'10'}>10</MenuItem>
                                <MenuItem value={'20'}>20</MenuItem>
                                <MenuItem value={'50'}>50</MenuItem>
                                <MenuItem value={'100'}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid sx={{ padding: '10px' }} item xs={3} sm={2}>
                        <Button fullWidth onClick={() => { setPage(1); formik.handleSubmit(); }} variant="outlined" >Search</Button>
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={3} sm={2}>
                        <Button fullWidth onClick={() => handleExport('1', 'Glossing.xlsx')} variant="outlined" >Glossing</Button>
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={3} sm={2}>
                        <Button fullWidth onClick={() => handleExport('2','Word Order.xlsx')} variant="outlined" >Word Order</Button>
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={3} sm={2}>
                        <Button fullWidth onClick={() => handleExport('3', 'Information Structure.xlsx')} variant="outlined" >Information Structure</Button>
                    </Grid>
                    <Grid sx={{ padding: '10px' }} item xs={3} sm={2}>
                        <Button fullWidth onClick={() => handleExport('4', 'List of Clauses.xlsx')} variant="outlined" >List of Clauses</Button>
                    </Grid>
                </Grid>
            </Paper>


            <Typography ref={myRef} variant="h5" gutterBottom sx={{ marginTop: '20px' }}>
                <span style={{ marginRight: '10px' }}>{textCount} texts</span>
                <span>{clauseCount} clauses</span></Typography>
            <div className="tableWithVerticalLines">
                <TableContainer >
                    <Table>
                        <TableBody>
                            {texts.map(text =>
                                <TableRow
                                    key={text.id}
                                >
                                    <TableCell sx={{ verticalAlign: 'top' }}>
                                        <div style={{ width: "100px", whiteSpace: "normal" }}>{text.publication}</div>
                                        <div>{text.dublicate}</div>
                                        <div>{text.source}</div>
                                        <div>{text.time}</div>
                                        <div>{text.cth}</div>                                        
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: 'top' }}>
                                        <h4>{text.title}</h4>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    {text.clauses.map(clause =>
                                                        <TableRow
                                                            key={clause.id}
                                                        >
                                                            <TableCell sx={{ verticalAlign: 'top' }} width="150px">
                                                                <div>
                                                                    <span>{clause.paragraph}{clause.paragraphPostfix}</span>
                                                                    {clause.lineOriginal}
                                                                </div>
                                                                <div>
                                                                    <Button fullWidth disabled={clause.informationStructures.length == 0} onClick={() => { setCurrentClause(clause); setOpen1(true); }}>
                                                                        Information Structure
                                                                    </Button>

                                                                    <Button fullWidth disabled={clause.wordForms.length == 0} onClick={() => { setCurrentClause(clause); setOpen2(true); }}>
                                                                        word forms
                                                                    </Button>

                                                                    <Button fullWidth disabled={!clause.tregexAnnotation} onClick={() => { setCurrentClause(clause); setOpen3(true); }}>
                                                                        tregex annotation
                                                                    </Button>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell sx={{ verticalAlign: 'top' }}>
                                                                <div dangerouslySetInnerHTML={{ __html: clause.formattedSyllables }} ></div>
                                                                <div dangerouslySetInnerHTML={{ __html: clause.formattedNormalizedSpelling }} ></div>
                                                                <div>{clause.englishTranslation}</div>
                                                                <div><a target="_blank" href={`database?clause_id=${clause.id}`}>{clause.id}</a></div>
                                                            </TableCell>
                                                        </TableRow>)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <Stack spacing={2} sx={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Pagination count={count} shape="rounded" page={page} onChange={handleChangePage} />
                    </Stack>
                </TableContainer>
            </div >

            <Dialog onClose={() => setOpen1(false)} open={open1}>
                <DialogTitle>Information Structure</DialogTitle>
                <Table>
                    <TableBody>
                        {currentClause?.informationStructures.map(informationStructure =>
                            <TableRow
                                key={informationStructure.id}
                            >
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    {informationStructure.qud}
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    {informationStructure.text}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Dialog>
            <Dialog onClose={() => setOpen2(false)} open={open2}>
                <DialogTitle>Word forms</DialogTitle>
                <Table>
                    <TableBody>
                        {currentClause?.wordForms.map(wordForm =>
                            <TableRow
                                key={wordForm.id}
                            >
                                <TableCell sx={{ verticalAlign: 'top' }} dangerouslySetInnerHTML={{ __html: wordForm.formattedSyllables }}>
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top' }} dangerouslySetInnerHTML={{ __html: wordForm.formattedNormalizedSpelling }}>
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    {wordForm.translation}
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    {wordForm.poS}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Dialog>
            <Dialog onClose={() => setOpen3(false)} open={open3}>
                <>
                    <DialogTitle>tregex annotation</DialogTitle>
                    <img src={`databaseapi/tregex-annotation-schema/${currentClause?.id}`} alt="" />
                </>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Container>
    </>;
};