import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

interface ILayoutProps {
    children?: React.ReactNode
}

interface ILayoutState {
}

export default class Layout extends React.PureComponent<ILayoutProps, ILayoutState> {
    public render() {
        return (
            <React.Fragment>
                <NavMenu />
                <Container>
                    {this.props.children}
                </Container>
            </React.Fragment>
        );
    }
}