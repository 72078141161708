import * as React from 'react';
import { connect } from 'react-redux';
import homeImage from './images/home.jpg';
import abautUsImage from './images/aboutus.jpg';
import overview1 from './images/overview1.jpg';
import overview2 from './images/overview2.jpg';
import overview3 from './images/overview3.jpg';
import contactUs from './images/contact-us.jpg';


import './Home.css';

const Home = () => (
    <>
        <section
            id="home"
            style={{
                zIndex: 0,
                overflow: "hidden",
                display: "grid",
                position: "relative",
                alignItems: "center"
            }}
        >
            <div
                id="k9qiHkkyRpakUE0O"
                style={{
                    backgroundColor: "#000000",
                    transform: "scale(1, 1)",
                    overflow: "hidden",
                    width: "100%",
                    opacity: 1.0,
                    height: "100%",
                    top: "0%",
                    left: "0%",
                    position: "absolute"
                }}
            >
                <div
                    id="zcXwiEojJFoHCQQg"
                    style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        top: "-9.28819444%",
                        left: "0%",
                        width: "100%",
                        backgroundImage: `url(${homeImage})`,
                        backgroundPosition: "center",
                        position: "relative",
                        opacity: 1.0,
                        height: "118.57638889%"
                    }}
                ></div>
            </div>
            <div id="HbuUmODJ14o0hsFQ">
                <div id="JgWltxRSQecuLBD7">
                    <div
                        id="fU7tkTo1hepukUyo"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="FWKpxLZPtVSMD6Uo"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="XOQTWmHkkLy9Wrpj"
                                style={{
                                    textTransform: "uppercase",
                                    color: "#efefef",
                                    letterSpacing: "0em",
                                    fontFamily: "YACkoGNq-XY-0",
                                    lineHeight: "1.4em",
                                    textAlign: "center"
                                }}
                            >
                                <span
                                    id="k8dEEB3MEPraRZsi"
                                    style={{ color: "#efefef", fontWeight: 700 }}
                                >
                                    maria molina
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="LUGwir3xRlTRD2lm">
                    <div
                        id="dkPU1TmGv1AKETkY"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="lNRnLEMp1vutQ8iL"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="MPi1Sy8eTekqDdNY"
                                style={{
                                    textShadow: "0em 0.0375em 0.0375em rgba(0, 0, 0, 0.050000)",
                                    color: "#ffffff",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1.19em",
                                    textAlign: "center"
                                }}
                            >
                                <span id="KQkIFpra89stPalN" style={{ color: "#ffffff" }}>
                                    Annotated Corpus{" "}
                                </span>
                            </p>
                            <p
                                id="oyHTol275vR6XfkY"
                                style={{
                                    marginLeft: "0em",
                                    textShadow: "0em 0.0375em 0.0375em rgba(0, 0, 0, 0.050000)",
                                    color: "#ffffff",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1.19em",
                                    direction: "ltr",
                                    textAlign: "center"
                                }}
                            >
                                <span id="ReiWtssMg8jLRN9W" style={{ color: "#ffffff" }}>
                                    of Hittite clauses
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="nLz8o5QIHpOfb4iT">
                    <div
                        id="n6KaFnCyMpUuQmy8"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <svg
                            id="FTUUu8eZUX9thq3E"
                            viewBox="0 0 460.0774 210.444"
                            preserveAspectRatio="none"
                            style={{
                                overflow: "hidden",
                                top: "0%",
                                left: "0%",
                                width: "100%",
                                position: "absolute",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <g id="mG3MZqZXuB9IoAKk" style={{ transform: "scale(1, 1)" }}>
                                <path
                                    id="eFpI7dYFEu5CeVfr"
                                    d="M0.0,0.0 L460.07735414655156,0.0 L460.07735414655156,210.44395910171204 L0.0,210.44395910171204 L0.0,0.0"
                                    style={{ fill: "#4b39b5", opacity: 1.0 }}
                                ></path>
                            </g>
                        </svg>
                    </div>
                </div>
                <div id="hMgHE1NxF74OCY70">
                    <div
                        id="vD4T5yGhFfOQvbQc"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="DHe6FRRGw3jIt7LJ"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="j9Xf9BwSsmOCylSV"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "uppercase",
                                    color: "#efefef",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4LPsgQ-0",
                                    lineHeight: "1.4em",
                                    direction: "ltr",
                                    textAlign: "center"
                                }}
                            >
                                <span
                                    id="pnvrPPAgJAhDc6pQ"
                                    style={{ color: "#efefef", fontWeight: 700 }}
                                >
                                    under construction
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
            id="about-us"
            style={{
                zIndex: 0,
                overflow: "hidden",
                display: "grid",
                marginTop: "-1px",
                position: "relative",
                alignItems: "center"
            }}
        >
            <div
                id="D4bpeCTMjMflcB2U"
                style={{
                    backgroundColor: "#efefef",
                    transform: "scale(1, 1)",
                    overflow: "hidden",
                    width: "100%",
                    opacity: 1.0,
                    height: "100%",
                    top: "0%",
                    left: "0%",
                    position: "absolute"
                }}
            ></div>
            <div id="mslfWaka4EIxlFZR">
                <div id="X7chHLFX4Vrp03H7">
                    <div
                        id="FM8gUz5WqoHvAEl0"
                        style={{ paddingTop: "113.77777778%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="xIrlaIvKRkafYUna"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <div
                                id="Oa4roLLt7Bf7HPlq"
                                style={{ width: "100%", opacity: 1.0, height: "100%" }}
                            >
                                <div
                                    id="r0XGvaCDwedLQRUi"
                                    style={{
                                        transform: "scale(1, 1)",
                                        overflow: "hidden",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <div
                                        id="SDDm4cqx33u3bQtZ"
                                        style={{
                                            transform: "rotate(0deg)",
                                            top: "0%",
                                            left: "-57.8393%",
                                            width: "215.679%",
                                            position: "relative",
                                            opacity: 1,
                                            height: "100%"
                                        }}
                                    >
                                        <img
                                            src={abautUsImage}
                                            alt=""
                                            loading="lazy"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="uTzK5VU8vwUUZBgZ">
                    <div
                        id="xJvfRdDxlrLg3MOK"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="RezDz2NXlzP5iWmP"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="P0EYCdXlnLt86Jkl"
                                style={{
                                    marginLeft: "0em",
                                    color: "#4b39b5",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1.2em",
                                    direction: "ltr",
                                    textAlign: "left"
                                }}
                            >
                                <span id="DJNspKFiOvdlYiLL" style={{ color: "#4b39b5" }}>
                                    Hittite Empire
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="H6RgyLD4VERdtRRu">
                    <div
                        id="r0YyszsbaB4zm56C"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="QHh5nuRXg1gCPCEr"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="sAdKEAfggAHbYals"
                                style={{
                                    color: "#000000",
                                    fontFamily: "YALBs4LPsgQ-0",
                                    lineHeight: "1.4em",
                                    textAlign: "justify"
                                }}
                            >
                                <span id="am3RZrFDVZxFmtWe" style={{ color: "#000000" }}>
                                    One of the Great Kingdoms of the Bronze Age.
                                </span>
                            </p>
                            <p
                                id="CI0j2g7nJin8DZtw"
                                style={{
                                    color: "#000000",
                                    fontFamily: "YALBs4LPsgQ-0",
                                    lineHeight: "1.4em",
                                    textAlign: "justify"
                                }}
                            >
                                <span id="lFlUsvrJyqfwHGyL" style={{ color: "#000000" }}>
                                    Existed in the II millennium BC on the territory of modern
                                    Turkiye.{" "}
                                </span>
                            </p>
                            <p
                                id="PhEE1Spm5FVBCI5O"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#000000",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4LPsgQ-0",
                                    lineHeight: "1.4em",
                                    direction: "ltr",
                                    textAlign: "justify"
                                }}
                            >
                                <span id="ZgWIYxfIlElwO1nf" style={{ color: "#000000" }}>
                                    Its capital, the city of Hattusa, was situated in Central
                                    Anatolia.
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="aiMRIzucIUHwjbv1">
                    <div
                        id="ARYu6jNtRZyYWOLg"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="tu3XsNs76bI2R2GR"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="dzqp2H5uHmMFzFDD"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "uppercase",
                                    color: "#4b39b5",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1.4em",
                                    direction: "ltr",
                                    textAlign: "left"
                                }}
                            >
                                <span
                                    id="VdnKk4BM0ZPAhyXO"
                                    style={{ textDecorationLine: "underline", color: "#4b39b5" }}
                                >
                                    photo: Hattusa (Bogazkoy, Turkiye){" "}
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
            id="overview"
            style={{
                zIndex: 0,
                overflow: "hidden",
                display: "grid",
                marginTop: "-1px",
                position: "relative",
                alignItems: "center"
            }}
        >
            <div
                id="ojvbjLLdWXeYtUku"
                style={{
                    backgroundColor: "#efefef",
                    transform: "scale(1, 1)",
                    overflow: "hidden",
                    width: "100%",
                    opacity: 1.0,
                    height: "100%",
                    top: "0%",
                    left: "0%",
                    position: "absolute"
                }}
            ></div>
            <div id="C1N9BH1yKSRisNwC">
                <div id="CiCgy8LhKH3wTChD">
                    <div
                        id="BtBLVWvD1QWlmx2y"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="v3O4gsRi4x4B2hmr"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="SInxiM9NVo0ZboPc"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#4b39b5",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1.2em",
                                    direction: "ltr",
                                    textAlign: "left"
                                }}
                            >
                                <span id="jfck93oooJ12Z7do" style={{ color: "#4b39b5" }}>
                                    Hittite as a language
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="S7XniEn3tn2Gldl7">
                    <div
                        id="BXmDE5bLHwwBQfr2"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="RZ92UQVpwSfbkZLu"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="s0AnfmGi6kKN9LV5"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#000000",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4LPsgQ-0",
                                    lineHeight: "1.4em",
                                    direction: "ltr"
                                }}
                            >
                                <span id="CAzUM8pXNu7KsGp9" style={{ color: "#000000" }}>
                                    The most ancient of the Indo-European languages attested in
                                    writing
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="mA0bJ7xv8ZCuy4gY">
                    <div
                        id="KWm45bAXlm2NfBsB"
                        style={{ paddingTop: "88.56329868%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="dBU9b1bqqQEZSdK8"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <div
                                id="To71XdfllPKU3C2z"
                                style={{ width: "100%", opacity: 1.0, height: "100%" }}
                            >
                                <div
                                    id="WT8p5zehkle0iLdC"
                                    style={{
                                        transform: "scale(1, 1)",
                                        overflow: "hidden",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <div
                                        id="G7ZjPy49iuqNhzA7"
                                        style={{
                                            transform: "rotate(0deg)",
                                            top: "0%",
                                            left: "0%",
                                            width: "140.14%",
                                            position: "relative",
                                            opacity: 1,
                                            height: "100%"
                                        }}
                                    >
                                        <img
                                            src={overview1}
                                            alt=""
                                            loading="lazy"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="cD48Ftz5DbZWuYXV">
                    <div
                        id="ZF6d0Pcf9NR23Z6N"
                        style={{ paddingTop: "9.15382998%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="K4gAXsw6a8f2si8y"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <div
                                id="LPgUvwVCLaXw55sd"
                                style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    opacity: 1.0,
                                    height: "100%"
                                }}
                            >
                                <p
                                    id="F0u4IukwGoJjzCY4"
                                    style={{
                                        marginLeft: "0em",
                                        textTransform: "none",
                                        color: "#ffffff",
                                        letterSpacing: "0em",
                                        fontFamily: "YALBs4gwU0s-0",
                                        lineHeight: "1.4em",
                                        direction: "ltr",
                                        textAlign: "left"
                                    }}
                                >
                                    <span id="ufanfQ95khqG2nk6" style={{ color: "#ffffff" }}>
                                        Writing: Cuneiform
                                    </span>
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="zsyLHq6Xol99A7yK">
                    <div
                        id="wXVKO38RhBxXSUJc"
                        style={{ paddingTop: "13.60553003%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="dprfnKrY8sG4xkHc"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <svg
                                id="wnVZTpWC68AE8uBX"
                                viewBox="0 0 984.5758 133.9568"
                                preserveAspectRatio="none"
                                style={{
                                    overflow: "hidden",
                                    top: "0%",
                                    left: "0%",
                                    width: "100%",
                                    position: "absolute",
                                    opacity: 1.0,
                                    height: "100%"
                                }}
                            >
                                <g id="OZdcjNa30b7brD5N" style={{ transform: "scale(1, 1)" }}>
                                    <path
                                        id="uNoHrg8ayYcPklKL"
                                        d="M0.0,0.0 L984.5757672923626,0.0 L984.5757672923626,133.9567490395808 L0.0,133.9567490395808 L0.0,0.0"
                                        style={{ fill: "#4b39b5", opacity: 1.0 }}
                                    ></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="Ml6mxKmz1PJGpZR3">
                    <div
                        id="Hpy4QcMeor5FKysG"
                        style={{ paddingTop: "13.49114953%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="wPE0T4JEsxvqQ3Yo"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <svg
                                id="QtINcXsuW6tnP51X"
                                viewBox="0 0 987.699 133.2519"
                                preserveAspectRatio="none"
                                style={{
                                    overflow: "hidden",
                                    top: "0%",
                                    left: "0%",
                                    width: "100%",
                                    position: "absolute",
                                    opacity: 1.0,
                                    height: "100%"
                                }}
                            >
                                <g id="qy8Sk3OQDLEpD6nu" style={{ transform: "scale(1, 1)" }}>
                                    <path
                                        id="ec6L4g9rIInetPIK"
                                        d="M0.0,0.0 L987.6990021223623,0.0 L987.6990021223623,133.25194661208485 L0.0,133.25194661208485 L0.0,0.0"
                                        style={{ fill: "#4b39b5", opacity: 1.0 }}
                                    ></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="zIWE5Ig6ycXhPAJC">
                    <div
                        id="KfeIn7GcwTYtS8wW"
                        style={{ paddingTop: "88.56329868%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="WMF3s04DaJoGSKLz"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <div
                                id="XGLDfCptkfL3VMPM"
                                style={{ width: "100%", opacity: 1.0, height: "100%" }}
                            >
                                <div
                                    id="Ffdo78Bn41zkPSah"
                                    style={{
                                        transform: "scale(1, 1)",
                                        overflow: "hidden",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <div
                                        id="m7kzcyVm4guaBkIy"
                                        style={{
                                            transform: "rotate(0deg)",
                                            top: "0%",
                                            left: "-31.9668%",
                                            width: "163.934%",
                                            position: "relative",
                                            opacity: 1,
                                            height: "100%"
                                        }}
                                    >
                                        <img
                                            src={overview2}
                                            alt=""
                                            loading="lazy"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="pT49w8ydjGFlJfM6">
                    <div
                        id="Z8tyUwHa9LwjHqx0"
                        style={{ paddingTop: "8.98944243%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="B4lvUZuEhP8AXik3"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <div
                                id="mt08oGYOLMMWebrE"
                                style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    opacity: 1.0,
                                    height: "100%"
                                }}
                            >
                                <p
                                    id="G6hbyOKDsrfiteSg"
                                    style={{
                                        marginLeft: "0em",
                                        textTransform: "none",
                                        color: "#ffffff",
                                        letterSpacing: "0em",
                                        fontFamily: "YALBs6uSPpE-0",
                                        lineHeight: "1.4em",
                                        direction: "ltr",
                                        textAlign: "left"
                                    }}
                                >
                                    <span
                                        id="FJGJgX5r5dPljAZ4"
                                        style={{ color: "#ffffff", fontWeight: 700 }}
                                    >
                                        Material: Clay tablets
                                    </span>
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="QBrHpZx1RqzW9shv">
                    <div
                        id="vjLxLPneMnhbkNs7"
                        style={{ paddingTop: "13.53690314%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="kubczlc6SVv3pNiE"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <svg
                                id="AAG676SziJh7ekcY"
                                viewBox="0 0 984.3607 133.2519"
                                preserveAspectRatio="none"
                                style={{
                                    overflow: "hidden",
                                    top: "0%",
                                    left: "0%",
                                    width: "100%",
                                    position: "absolute",
                                    opacity: 1.0,
                                    height: "100%"
                                }}
                            >
                                <g id="ZaAlrSF5zZ5zY0Gx" style={{ transform: "scale(1, 1)" }}>
                                    <path
                                        id="cX5hHAvWPNiTB2aa"
                                        d="M0.0,0.0 L984.3606612704834,0.0 L984.3606612704834,133.2519466120849 L0.0,133.2519466120849 L0.0,0.0"
                                        style={{ fill: "#4b39b5", opacity: 1.0 }}
                                    ></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="gOf2sbBvgVtHTIqH">
                    <div
                        id="oN28tOkgaF504T2v"
                        style={{ paddingTop: "88.56329868%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="BDIJXvC713niXiRr"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <div
                                id="tij7apqsNGEBrc2I"
                                style={{ width: "100%", opacity: 1.0, height: "100%" }}
                            >
                                <div
                                    id="hF9sIaRsId4r2wYK"
                                    style={{
                                        transform: "scale(1, 1)",
                                        overflow: "hidden",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <div
                                        id="XIue9jzf5xpfTT2h"
                                        style={{
                                            transform: "rotate(0deg)",
                                            top: "0%",
                                            left: "-40.9235%",
                                            width: "181.847%",
                                            position: "relative",
                                            opacity: 1,
                                            height: "100%"
                                        }}
                                    >
                                        <img
                                            src={overview3}
                                            alt=""
                                            loading="lazy"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="sA0Sb3KeuU30Owso">
                    <div
                        id="xKCUQVDJh9c3JQVS"
                        style={{ paddingTop: "8.78478959%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="ndUVjU9n2elqr8g6"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <div
                                id="yj75sEY0Z95ZD3Ez"
                                style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    opacity: 1.0,
                                    height: "100%"
                                }}
                            >
                                <p
                                    id="mdvxpFdECEfvLh7x"
                                    style={{
                                        marginLeft: "0em",
                                        textTransform: "none",
                                        color: "#ffffff",
                                        letterSpacing: "0em",
                                        fontFamily: "YALBs6uSPpE-0",
                                        lineHeight: "1.4em",
                                        direction: "ltr",
                                        textAlign: "left"
                                    }}
                                >
                                    <span
                                        id="YHiKa0H5deD0k3yu"
                                        style={{ color: "#ffffff", fontWeight: 700 }}
                                    >
                                        Word order: SOV
                                    </span>
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
            id="our-mission"
            style={{
                zIndex: 0,
                overflow: "hidden",
                display: "grid",
                marginTop: "-1px",
                position: "relative",
                alignItems: "center"
            }}
        >
            <div
                id="fNmPh1Wh8wSI0y5d"
                style={{
                    backgroundColor: "#4b39b5",
                    transform: "scale(1, 1)",
                    overflow: "hidden",
                    width: "100%",
                    opacity: 1.0,
                    height: "100%",
                    top: "0%",
                    left: "0%",
                    position: "absolute"
                }}
            ></div>
            <div id="QodLsfxDptyp3KWz">
                <div id="lv2QjCn1yzByfbzi">
                    <div
                        id="qJjmSQqeEKI9ntSB"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="hfCQgQYbgb0qCsAT"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="LR1aAuBKDLPZ9bNQ"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#ffffff",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1.2em",
                                    direction: "ltr",
                                    textAlign: "center"
                                }}
                            >
                                <span id="GaCLQNWYOeZpKHAU" style={{ color: "#ffffff" }}>
                                    Our Mission
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="GwaR0SgkEPlTzYGV">
                    <div
                        id="l7UDD4CaQMmX0R99"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="IDhDkTntd0IEJEiC"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="F68MnCFp8odiPN6a"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#ffffff",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4LPsgQ-0",
                                    lineHeight: "1.2em",
                                    direction: "ltr",
                                    textAlign: "center"
                                }}
                            >
                                <span
                                    id="F35a66WYkxNCqaYQ"
                                    style={{ color: "#ffffff", fontWeight: 700 }}
                                >
                                    To build the first annotated corpus of Hittite clauses with
                                    syntactic annotation
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
            id="contact-us"
            style={{
                zIndex: 0,
                overflow: "hidden",
                display: "grid",
                marginTop: "-1px",
                position: "relative",
                alignItems: "center"
            }}
        >
            <div
                id="fAnxw7Xg5m6SoFSs"
                style={{
                    backgroundColor: "#efefef",
                    transform: "scale(1, 1)",
                    overflow: "hidden",
                    width: "100%",
                    opacity: 1.0,
                    height: "100%",
                    top: "0%",
                    left: "0%",
                    position: "absolute"
                }}
            ></div>
            <div id="aziaMUz4C5EPZr04">
                <div id="SuZXj2N9cACmIxny">
                    <div
                        id="fnhS98qnNYW2iVTx"
                        style={{ paddingTop: "31.21441144%", transform: "rotate(0deg)" }}
                    >
                        <div
                            id="TKpFHqhy7olTwH58"
                            style={{
                                top: 0,
                                left: 0,
                                width: "100%",
                                position: "absolute",
                                height: "100%"
                            }}
                        >
                            <div
                                id="G41mXvkdgk580tN7"
                                style={{ width: "100%", opacity: 1.0, height: "100%" }}
                            >
                                <div
                                    id="g8yl3ZBhaHQ2QA9n"
                                    style={{
                                        transform: "scale(1, 1)",
                                        overflow: "hidden",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <div
                                        id="QYuiyAOnTlda3Re4"
                                        style={{
                                            transform: "rotate(0deg)",
                                            top: "-39.8492%",
                                            left: "0%",
                                            width: "100%",
                                            position: "relative",
                                            opacity: 1,
                                            height: "179.698%"
                                        }}
                                    >
                                        <img
                                            src={contactUs}
                                            alt=""
                                            loading="lazy"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Qwf51GUBI1JmeaZM">
                    <div
                        id="odPc4EuL3FeTr62m"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="HfKb3ZVC1r3dRKcK"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="RKV28sOGtmgiMKxG"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#4b39b5",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1em",
                                    direction: "ltr",
                                    textAlign: "left"
                                }}
                            >
                                <span
                                    id="ZQSU6CVSbTlO1ndV"
                                    style={{
                                        textDecorationLine: "none",
                                        color: "#4b39b5",
                                        fontStyle: "normal"
                                    }}
                                >
                                    Contact Information
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="DIVF2F7FzRd3Lq8F">
                    <div
                        id="XaqD6Ig3QeeLTIZn"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="NQ2kb05lkiQaGmUI"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="TxMHlO4kjEhN1hgI"
                                style={{
                                    textTransform: "none",
                                    color: "#000000",
                                    letterSpacing: "0em",
                                    fontFamily: "YALBs4LPsgQ-0",
                                    lineHeight: "1.4em"
                                }}
                            >
                                <span
                                    id="BHmBRAGNWgIyhYxD"
                                    style={{ color: "#000000", fontWeight: 700 }}
                                >
                                    How to get in touch with us.
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="GMIU0uUtq0hk0rDs">
                    <div
                        id="aoNuRM5PP0DXGW9E"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="kM3JjwiULy16RQPv"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="SouG0sLuPkc8tH0h"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#4b39b5",
                                    letterSpacing: "0.02em",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1.4em",
                                    direction: "ltr",
                                    textAlign: "left"
                                }}
                            >
                                <span
                                    id="LrDqciKf47uBiwMN"
                                    style={{
                                        textDecorationLine: "none",
                                        color: "#4b39b5",
                                        fontWeight: 700,
                                        fontStyle: "normal"
                                    }}
                                >
                                    Phone Number
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="QgHmbRZTPfVip7Dr">
                    <div
                        id="wjxKOdnMmlZqzWBk"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="sFy2FdMHnIBezvRK"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-end",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="ydbt0F2Z8Y1VVpVV"
                                style={{
                                    textTransform: "none",
                                    color: "#000000",
                                    letterSpacing: "0.05em",
                                    fontFamily: "YACgERY7hhg-0",
                                    lineHeight: "1.4em"
                                }}
                            >
                                <span
                                    id="fJZP3QmGI8dQvQhZ"
                                    style={{ color: "#000000", fontWeight: 700 }}
                                >
                                    +972 53 270 5217
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="aWp8grXD3ks0r4nl">
                    <div
                        id="DT6MMGtiWuNtFaRK"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="BzUV5iVrz3Q6e0dC"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="M0vjcOUUOgFqamfH"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#4b39b5",
                                    letterSpacing: "0.02em",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1.4em",
                                    direction: "ltr",
                                    textAlign: "left"
                                }}
                            >
                                <span
                                    id="tnQbf9gPgk6LxYLb"
                                    style={{
                                        textDecorationLine: "none",
                                        color: "#4b39b5",
                                        fontWeight: 700,
                                        fontStyle: "normal"
                                    }}
                                >
                                    Email Address
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="cDdEGnAHCFfc1Udv">
                    <div
                        id="u3hHIaAIi6TB3WGY"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="UeahrA7P3ZLoOUNb"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-end",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="Mh7wArYU8AwuRZOS"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#000000",
                                    letterSpacing: "0.05em",
                                    fontFamily: "YACgERY7hhg-0",
                                    lineHeight: "1.4em",
                                    direction: "ltr",
                                    textAlign: "left"
                                }}
                            >
                                <a
                                    id="hirdIf9fmILBLoMv"
                                    href="/_link/?link=mailto%3Amariyah.molina%40gmail.com&target=hb7tEir8Q3pn8gliZa8%2FPntxmkozyjoC7DEYy95BGLhtqfQUMDhGtELqHJfEXqdqnjPc7skI8M0Mwt9Uwzow8F6ys44Lp%2BFwcC0WWgtFWwEQBqugA46sEiCjfy4%3D&iv=jnTNiRCLhfC2OrIK"
                                    style={{
                                        textDecorationLine: "underline",
                                        color: "#000000",
                                        fontWeight: 700,
                                        pointerEvents: "all"
                                    }}
                                    target="_blank"
                                >
                                    mariyah.molina@gmail.com
                                </a>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="R19Xoe2SyyhiFsAe">
                    <div
                        id="nj4E8JEaiVXdtJOl"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="WeQmrVLkvJjniF8X"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="iiGmAZwZshmnqwEg"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#4b39b5",
                                    letterSpacing: "0.02em",
                                    fontFamily: "YALBs4gwU0s-0",
                                    lineHeight: "1.4em",
                                    direction: "ltr",
                                    textAlign: "left"
                                }}
                            >
                                <span
                                    id="eZP7CCBLGC7Tbafa"
                                    style={{
                                        textDecorationLine: "none",
                                        color: "#4b39b5",
                                        fontWeight: 700,
                                        fontStyle: "normal"
                                    }}
                                >
                                    Mailing Address
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="hb3mN8t2YQJ86OWQ">
                    <div
                        id="vcEKb7QmeH5iJVP6"
                        style={{
                            transform: "rotate(0deg)",
                            width: "100%",
                            boxSizing: "border-box",
                            height: "100%"
                        }}
                    >
                        <div
                            id="fQOut8BW7N2DmeVX"
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-end",
                                opacity: 1.0,
                                height: "100%"
                            }}
                        >
                            <p
                                id="TRP9OccF5XhPt9MI"
                                style={{
                                    marginLeft: "0em",
                                    textTransform: "none",
                                    color: "#000000",
                                    letterSpacing: "0.05em",
                                    fontFamily: "YACgERY7hhg-0",
                                    lineHeight: "1.4em",
                                    direction: "ltr",
                                    textAlign: "left"
                                }}
                            >
                                <span
                                    id="qDADkZwBQ1wK2FTU"
                                    style={{ color: "#000000", fontWeight: 700 }}
                                >
                                    fl. 6, Shmuel Ha-Nagid 17, Jerusalem, Israel
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
);

export default connect()(Home);
