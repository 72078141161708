import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <nav style={{ backgroundColor: "#ffffff", zIndex: "1", top: "0px", display: "flex", fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif", justifyContent: "flex-end", position: "sticky", alignItems: "center", textOverflow: "ellipsis", boxSizing: "border-box", borderBottom: "1px solid rgba(0, 0, 0, 0.07)", height: "50px", }}>
                <input id="menuToggle" type="checkbox" style={{ display: "none", }} />
                <label id="menuButton" htmlFor="menuToggle">
                    <div id="menuButtonIcon" style={{ padding: "8px", width: "24px", }}>
                        <svg viewBox="0 0 24.0 24.0" preserveAspectRatio="none">
                            <path d="M5.75 5.25h12.5a.75.75 0 1 1 0 1.5H5.75a.75.75 0 0 1 0-1.5zm0 6h12.5a.75.75 0 1 1 0 1.5H5.75a.75.75 0 1 1 0-1.5zm0 6h12.5a.75.75 0 1 1 0 1.5H5.75a.75.75 0 1 1 0-1.5z">
                            </path>
                        </svg>
                    </div>
                </label>
                <ul id="verticalMenu">
                    <li className="navMenuLink"><a className="navMenuLinkContent" href="http://hittitecorpus.com">Home</a></li>
                    <li className="navMenuLink"><NavLink tag={Link} className="navMenuLinkContent" to="/database">Database</NavLink></li>
                </ul>
                <ul id="horizontalMenu" style={{ padding: "0", margin: "0", }}>
                    <li className="navMenuLink"><a className="navMenuLinkContent" href="http://hittitecorpus.com">Home</a></li>
                    <li className="navMenuLink"><NavLink tag={Link} className="navMenuLinkContent" to="/database">Database</NavLink></li>
                </ul>
            </nav>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
