import * as React from 'react';

import { uploadFile } from '../api';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';


//const Item = styled(Paper)(({ theme }) => ({
//    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//    ...theme.typography.body2,
//    padding: theme.spacing(1),
//    textAlign: 'center',
//    color: theme.palette.text.secondary,
//}));


export const ManageDatabase = () => {

    const [selectedFiles, setSelectedFiles] = React.useState();
    const [currentFile, setCurrentFile] = React.useState<File>();
    const [progress, setProgress] = React.useState<number>(0);
    const [message, setMessage] = React.useState<string>('');
    // fileInfos,
    const [isError, setIsError] = React.useState<boolean>(false);

    const onProgress = React.useCallback((p: number) => {
        setProgress(p);
    }, []);

    const handleSelectFile: React.ChangeEventHandler<HTMLInputElement> = React.useCallback((event) => {
        if (!event.target.files)
            return;

        uploadFile.upload('managedatabase/upload-file', event.target.files[0], onProgress);
    }, []);
    return <>
        <Container maxWidth="sm">
            <div className="mg20">
                {progress > 0 && (
                    <Box className="mb25" display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                        </Box>
                    </Box>)
                }

                <label htmlFor="btn-upload">
                    <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={handleSelectFile}
                    />
                    <Button
                        className="btn-choose"
                        variant="outlined"
                        component="span" >
                        Choose Files
                    </Button>
                </label>
            </div >
        </Container>
    </>;
};